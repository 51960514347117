export const minimumPricePerPiece = 7.00
export const minimumSubtotal = 30.00

export const calculatePrice = ({
  volume,
  unit = 'mm',
  quantity = 1,
  pricePerGram = 1.25 / 1000,
}) => {
  if(unit === 'mm') {
    const price = volume * pricePerGram

    let finalPrice
    if(volume === 0) {
      finalPrice = 0
    } else if(price < minimumPricePerPiece) {
      finalPrice = price * 0.5 + minimumPricePerPiece
    } else {
      finalPrice = price
    }

    return parseFloat((finalPrice * quantity).toFixed(2))
  } else if (unit === 'in') {
    // mm to inch cube conversion
    const price = volume * pricePerGram * 16387.064069264

    let finalPrice
    if(volume === 0) {
      finalPrice = 0
    } else if (price < minimumPricePerPiece) {
      finalPrice = price * 0.5 + minimumPricePerPiece
    } else {
      finalPrice = price
    }

    return parseFloat((finalPrice * quantity).toFixed(2))
  }
  
}

export const calculateMinimumOrderValueSurcharge = (subtotal) => {
  if(subtotal === minimumSubtotal) {
    return 0
  }

  if(subtotal > minimumSubtotal) {
    return 0
  }

  // There's a surcharge if subtotal is less than minimumSubtotal 
  if(subtotal < minimumSubtotal) {
    return parseFloat((minimumSubtotal - subtotal).toFixed(2))
  }
}