import React from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { css } from 'styled-components'

import { space, fontSizes, colors, mediaQueries } from '../../utils/tokens'

const RightArrow = ({
  onClick,
  ...rest
}) => {
  return (
    <button
      aria-label="Next page"
      css={css({
        background: `unset`,
        padding: 0,
        border: 0,
        position: `fixed`,
        top: `50%`,
        right: `${space[3]}px`,
        display: `flex`,
        alignItems: `center`,
        [mediaQueries.xxl]: {
          right: `${space[5]}px`,
        }
      })}
      onClick={onClick}
      {...rest}
    >
      <FaLongArrowAltRight
        css={css({
          fontSize: fontSizes[4],
          color: colors.white,
          cursor: `pointer`,
          translteY: `-50%`,
        })}
      />
    </button>
  )
}

export default RightArrow
