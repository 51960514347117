import React from 'react'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { css } from 'styled-components'

import { space, fontSizes, mediaQueries, colors } from '../../utils/tokens'

const LeftArrow = ({
  onClick,
  ...rest
}) => {
  return (
    <button
      aria-label="Previous page"
      css={css({
        background: `unset`,
        padding: 0,
        border: 0,
        position: `fixed`,
        top: `50%`,
        left: `${space[3]}px`,
        display: `flex`,
        alignItems: `center`,
        [mediaQueries.xxl]: {
          left: `${space[5]}px`,
        }
      })}
      onClick={onClick}
      {...rest}
    >
      <FaLongArrowAltLeft
        css={css({
          fontSize: fontSizes[4],
          color: colors.white,
          cursor: `pointer`,
          translateY: `-50%`,
        })}
      />
    </button>
  )
}

export default LeftArrow
