import React, { useState } from 'react'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Skeleton from 'react-loading-skeleton'
import { css } from 'styled-components'
import {
  IoMdInformationCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io'
import { button } from '../../../utils/styles'
import { space, mediaQueries, colors, breakpoints, fontSizes, letterSpacings, palette } from '../../../utils/tokens'

const BottomNavRetail = ({
  subTotal,
  valueSurcharge,
  onSubmit,
  isPriceHidden,
}) => {
  const [showModal, setShowModal] = useState(false)

  const open = () => setShowModal(true)
  const close = () => setShowModal(false)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const formattedSubTotal= formatter.format(subTotal)
  const formattedValueSurcharge = formatter.format(valueSurcharge)

  return (
    <div
      css={css({
        backgroundColor: colors.white,
        padding: `${space[4]}px ${space[3]}px ${space[6]}px`,
        [mediaQueries.xl]: {
          width: breakpoints.sm,
          margin: `0 auto`,
          padding: `${space[4]}px ${space[4]}px ${space[5]}px`,
          border: `1px solid ${palette.grey[40]}`,
          borderBottom: `none`,
        },
      })}
    >
      <section
        css={css({
          marginBottom: `${space[3]}px`,
        })}
      >
        <div
          css={css({
            display: `flex`,
            alignItems: `baseline`,
            justifyContent: `space-between`,
            fontSize: fontSizes[3],
            fontWeight: `bold`,
          })}
        >
          <h5
            css={css({
              fontSize: fontSizes[3],
              fontWeight: `bold`,
            })}
          >
            Subtotal
          </h5>
          {isPriceHidden ? (
            <div css={css({
              marginLeft: `auto`,
              width: `30%`,
            })}>
              <Skeleton height={32}  />
            </div>
          ) : (
            <div>
              <span
                css={css({
                  letterSpacing: letterSpacings.tight,
                })}
              >
                {formattedSubTotal}
              </span>
            </div>
          )}
        </div>
        {valueSurcharge ? (
          <div
            css={css({
              display: `flex`,
              alignItems: `baseline`,
              justifyContent: `space-between`,
              fontSize: fontSizes[2],
              fontWeight: `500`,
              color: palette.orange[60],
            })}
          >
            <h5
              css={css({
                color: palette.orange[60],
                fontSize: fontSizes[2],
              })}
            >
              Mininum order surcharge
              <button
                onClick={open}
                css={css({
                  border: `none`,
                  background: `unset`,
                  padding: 0,
                  color: palette.orange[60],
                  cursor: `pointer`,
                  position: `relative`,
                })}
              >
                <IoMdInformationCircleOutline />
                <SurchargeDialog close={close} showModal={showModal} />
              </button>
            </h5>
            <span>{formattedValueSurcharge}</span>
          </div>
        ) : null}
      </section>
      <hr />
      <section
        css={css({
          marginBottom: `${space[4]}px`,
        })}
      >
        <h5
          css={css({
            fontSize: fontSizes[2],
            fontWeight: `500`,
            color: palette.grey[70],
            marginBottom: `${space[2]}px`,
          })}
        >
          Production time:
        </h5>
        <span
          css={css({
            fontWeight: `bold`,
            fontSize: fontSizes[2],
            color: palette.grey[70],
          })}
        >
          2 to 6 Business Days
        </span>
      </section>
      <button
        css={css({
          ...button.base,
          ...button.primary,
          borderRadius: `6px`,
          border: `none`,
          paddingTop: `${space[2]}px`,
          paddingBottom: `${space[2]}px`,
          display: `flex`,
          justifyContent: `center`,
          alignSelf: `flex-end`,
          alignItems: `center`,
          width: `100%`,
          [mediaQueries.lg]: {
            width: `auto`,
          },
        })}
        onClick={onSubmit}
      >
        SEND FOR APPROVAL
      </button>
    </div>
  )
}


const SurchargeDialog = ({
  showModal,
  close,
}) => {
  return (
    <DialogOverlay
      isOpen={showModal}
      onDismiss={close}
      css={css({
        display: `flex`,
        alignItems: `center`,
      })}
    >
      <DialogContent
        css={css({
          position: `relative`,
        })}
      >
        <button
          aria-label="close"
          onClick={close}
          css={css({
            border: 0,
            alignSelf: `flex-end`,
            background: `unset`,
            padding: 0,
            display: `flex`,
            alignItems: `center`,
            cursor: `pointer`,
            position: `absolute`,
            right: `${space[3]}px`,
            top: `${space[3]}px`,
            fontSize: fontSizes[3],
            color: colors.danger,
          })}
        >
          <IoMdCloseCircleOutline />
        </button>
        <h5
          css={css({
            fontSize: fontSizes[2],
            marginBottom: `${space[3]}px`,
          })}
        >
          We have a minimum order of $30.
        </h5>
        <p>
          Increase the quantity or add more files to make the most out of your
          order.
        </p>
      </DialogContent>
    </DialogOverlay>
  )
}

export default BottomNavRetail