import React from 'react'
import { css } from 'styled-components'

import { space, fontSizes, palette } from '../../utils/tokens'

import Modal from '../Modal'
import FormTextInput from '../../views/custom-quote/components/FormTextInput'
import FormSubmitButton from '../../views/custom-quote/components/FormSubmitButton'
import FormLabel from './FormLabel'
import AnimatedEllipsis from '../AnimatedEllipsis'

const SubmitModalScreenAnon = ({
  isOpen,
  onDismiss,
  name,
  email,
  additionalInformation,
  isUploading,
  onAdditionalInformationChange,
  onEmailChange,
  onNameChange,
  onSubmit
}) => {

  const handleDismiss = () => {
    // dont dismiss if we're still uploading
    if(isUploading) {
      return
    }
    onDismiss && onDismiss()
  }

  const handleSubmit = (e) => {
    if(isUploading) {
      return
    }
    onSubmit && onSubmit()

    e.preventDefault()
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={handleDismiss}
      ariaLabel="Submit information"
    >
      <div
        css={css({
          marginTop: `${space[3]}px`,
          marginBottom: `${space[4]}px`,
        })}
      >
        <h2
          css={css({
            color: palette.grey[90],
            fontWeight: `bold`,
          })}
        >
          One last step so we can reach you!
        </h2>
        <p>(We won't spam, we promise.)</p>
      </div>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel
            required
            css={css({
              marginBottom: `${space[3]}px`,
              fontSize: fontSizes[2],
              color: palette.grey[70],
            })}
          >
            Name*
          </FormLabel>
          <FormTextInput required onChange={onNameChange} value={name} />
        </FormGroup>
        <FormGroup>
          <FormLabel
            css={css({
              marginBottom: `${space[3]}px`,
              fontSize: fontSizes[2],
              color: palette.grey[70],
            })}
          >
            Work email*
          </FormLabel>
          <FormTextInput
            required
            type="email"
            onChange={onEmailChange}
            value={email}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel
            css={css({
              marginBottom: `${space[3]}px`,
              fontSize: fontSizes[2],
              color: palette.grey[70],
            })}
          >
            Additional information
          </FormLabel>
          <textarea
            onChange={onAdditionalInformationChange}
            value={additionalInformation}
            placeholder="Tell us anything about the project or any special instructions."
            rows="5"
            css={css({
              width: `100%`,
              borderRadius: `${space[1]}px`,
              border: `1px solid ${palette.grey[50]}`,
              padding: `${space[2]}px`,
            })}
          />
        </FormGroup>
        <div
          css={css({
            display: `flex`,
            alignItems: `center`,
          })}
        >
          <FormSubmitButton
            label="Submit"
            isLoading={isUploading}
            disabled={isUploading}
          />
          <div
            css={css({
              marginLeft: `${space[3]}px`,
            })}
          >
            {isUploading ? (
              <AnimatedEllipsis
                label="Uploading your files right now"
                css={css({
                  fontSize: fontSizes[2],
                  fontWeight: `normal`,
                  color: palette.grey[60],
                })}
              />
            ) : null}
          </div>
        </div>
      </form>
    </Modal>
  )
}

const FormGroup = ({ children }) => {
  return (
    <div
      css={css({
        marginBottom: `${space[4]}px`,
      })}
    >{children}</div>
  )
}

export default SubmitModalScreenAnon