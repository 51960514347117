import shortid from 'shortid'

export const generateUniqueFileName = (filename) => {
  if (!filename) {
    return
  }

  const generatedId = shortid.generate()

  const noSpaceName = filename.split(' ').join('_')

  return `${generatedId}-${noSpaceName}`
}