import React, { useEffect, useState } from 'react'
import { css } from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Canvas } from 'react-three-fiber'
import mousetrap from 'mousetrap'

import '@reach/dialog/styles.css'

import Model from './Model'
import RightArrow from '../RightArrow'
import LeftArrow from '../LeftArrow'

import { fontSizes, colors, space, mediaQueries } from '../../../utils/tokens'

const CanvasModal = ({
  onDismiss,
  isOpen,
  models,
  startingModelIndex,
}) => {

  const [currentIndex, setCurrentIndex] = useState(0)

  // set currentIndex based on startingModelIndex
  useEffect(() => {
    setCurrentIndex(startingModelIndex)
  }, [startingModelIndex])

  const model = models ? models[currentIndex] : null


  useEffect(() => {
    mousetrap.bind(`right`, next)
    mousetrap.bind(`left`, previous)
    mousetrap.bind(`escape`, dismiss)

    return () => {
      mousetrap.unbind(`left`)
      mousetrap.unbind(`right`)
      mousetrap.unbind(`escape`)
    }
  }, [`left`, `right`, `space`, `escape`, currentIndex])

  const next = e => {
    if (e) {
      e.stopPropagation()
    }

    if(currentIndex + 1 === models.length) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(previous => previous + 1)
    }
  }

  const previous = e => {
    if (e) {
      e.stopPropagation()
    }

    if(currentIndex === 0) {
      setCurrentIndex(models.length - 1)
    } else {
      setCurrentIndex((previous) => previous - 1)
    }
  }

  const dismiss = () => {
    onDismiss()
  }

  return (
    <DialogOverlay
      css={css({
        overflow: `hidden`,
      })}
      isOpen={isOpen}
      onDismiss={dismiss}
    >
      <DialogContent
        aria-label="3d model viewer"
        css={css({
          height: `80vh`,
          width: `100%`,
          [mediaQueries.lg]: {
            width: `50vw`,
          },
        })}
      >
        {model && (
          <div
            css={css({
              height: `100%`,
            })}
          >
            <Canvas
              css={css({
                cursor: `move`,
              })}
            >
              {model.bufferGeometry ? (
                <Model geometry={model.bufferGeometry} />
              ) : null}
            </Canvas>
            <div
              css={css({
                position: `absolute`,
                bottom: `${space[4]}px`,
                left: `${space[4]}px`,
              })}
            >
              <h5
                css={css({
                  fontSize: fontSizes[3],
                  fontWeight: `bold`,
                  color: colors.white,
                })}
              >
                {model.fileName}
              </h5>
            </div>
          </div>
        )}
        <RightArrow onClick={next} />
        <LeftArrow onClick={previous} />
      </DialogContent>
    </DialogOverlay>
  )
}

export default CanvasModal
