import React, { useState } from 'react'
import { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { space, mediaQueries, fontSizes, breakpoints } from '../../utils/tokens'

import MaterialSelectionCard from '../../components/InstantQuote/MaterialSelectionCard'
import FormLabel from '../../components/InstantQuote/FormLabel'
import Modal from '../Modal'

const MaterialSelectionList = ({
  selectedMaterialId = 0,
  onMaterialSelect,
  areModelsLoaded,
}) => {

  const [showModal, setShowModal] = useState(false)

  const data = useStaticQuery(query)

  const materials = data.allContentfulMaterial.edges
  
  return (
    <div
      css={css({
        marginBottom: `${space[4]}px`,
      })}
    >
      <div
        css={css({
          display: `flex`,
          alignItems: `baseline`,
          justifyContent: `space-between`,
          maxWidth: `600px`,
          padding: `0 ${space[3]}px`,
          [mediaQueries.xxl]: {
            padding: 0,
          },
        })}
      >
        <FormLabel>Select a material</FormLabel>
      </div>
      <div
        css={css({
          marginTop: `${space[3]}px`,
          padding: `0 ${space[3]}px`,
          display: `grid`,
          gridGap: `${space[4]}px`,
          [mediaQueries.md]: {
            gridTemplateColumns: `repeat(auto-fit,300px)`,
          },
        })}
      >
        {materials &&
          materials.map(({ node }, id) => {
            return (
              <MaterialSelectionCard
                key={node.id}
                name={node.name}
                description={
                  node.perfectFor && node.perfectFor.internal.content
                }
                thumbnail={node.coverPhoto}
                onCardSelect={() => onMaterialSelect(id)}
                isSelected={id === selectedMaterialId}
                areModelsLoaded={areModelsLoaded}
              />
            )
          })}
      </div>
      <Modal
        onDismiss={() => setShowModal(false)}
        isOpen={showModal}
        contentCss={css({
          maxWidth: breakpoints.lg,
        })}
      >
        <ModalContent />
      </Modal>
    </div>
  )
}

const query = graphql`
  query {
    allContentfulMaterial(filter: { isAvailableForInstantQuote: { eq: true } }) {
      edges {
        node {
          id
          coverPhoto {
            title
            fixed(width: 75 height: 75) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          name
          perfectFor {
            childMarkdownRemark {
              html
            }
            internal {
              content
            }
          }
          isAvailableForInstantQuote
        }
      }
    }
  }
`

const ModalContent = () => {
  return (
    <div>
      <h1
        css={css({
          fontSize: fontSizes[3],
          fontWeight: `bold`,
          marginBottom: `${space[3]}px`,
          [mediaQueries.xxl]: {
            fontSize: fontSizes[4],
          },
        })}
      >
        Why we don't offer ABS or PLA.
      </h1>
      <ul>
        <li>
          <h2> SLA Resin is just 100x better.</h2>
          <p>Higher resolution prints.</p>
        </li>
      </ul>
    </div>
  )
}

export default MaterialSelectionList