import React from 'react'
import Img from 'gatsby-image'
import { FaCheck, FaStar } from 'react-icons/fa'
import { css } from 'styled-components'
import { fontSizes, boxShadows, space, colors } from '../../utils/tokens'

const MaterialSelectionCard = ({
  name,
  thumbnail,
  isSelected,
  onCardSelect,
  description,
  areModelsLoaded,
  ...rest
}) => {

  const buttonStyleSelect = isSelected ? buttonStyle.selected : null
  return (
    <div
      css={css({
        display: `flex`,
        flexDirection: `column`,
        padding: `${space[3]}px`,
        borderRadius: `8px`,
        boxShadow: boxShadows.card,
        backgroundColor: colors.white,
      })}
      {...rest}
    >
      <div
        css={css({
          display: `grid`,
          gridTemplateColumns: thumbnail ? `1fr 2fr` : `1fr`,
          marginBottom: `${space[3]}px`,
          gridGap: `${space[2]}px`,
        })}
      >
        {thumbnail && <Img fixed={thumbnail.fixed} alt={thumbnail.title} />}
        <div
          css={css({
            display: `flex`,
            flexDirection: `column`,
          })}
        >
          <h5
            css={css({
              fontWeight: `bold`,
              color: colors.base,
              fontSize: fontSizes[2],
            })}
          >
            {name}
          </h5>
          <div
            css={css({
              fontSize: fontSizes[0],
              color: colors.primaryBrand,
            })}
          >
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
        </div>
      </div>
      <hr
        css={css({
          marginBottom: `${space[2]}px`,
        })}
      />
      <div
        css={css({
          flex: 1,
          marginTop: `${space[3]}px`,
          marginBottom: `${space[3]}px`,
        })}
      >
        <span
          css={css({
            display: `block`,
            fontWeight: `bold`,
            color: colors.base,
            fontSize: fontSizes[1],
            marginBottom: `${space[2]}px`,
          })}
        >
          Perfect for
        </span>
        <div>
          <p
            css={css({
              color: colors.base,
              fontSize: fontSizes[1],
              display: `inline`,
              marginRight: `${space[1]}px`,
            })}
          >
            {description}
          </p>
        </div>
      </div>
      <hr
        css={css({
          marginBottom: `${space[3]}px`,
        })}
      />
      <div
        css={css({
          width: `100%`,
          display: `flex`,
          justifyContent: `center`,
        })}
      >
        <button
          css={css({
            ...buttonStyle.base,
            ...buttonStyleSelect,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          })}
          onClick={onCardSelect}
        >
          {isSelected ? (
            <FaCheck
              css={css({
                marginRight: `${space[2]}px`,
                fontSize: fontSizes[0],
              })}
            />
          ) : null}
          {isSelected ? `Selected` : `Select`}
        </button>
      </div>
    </div>
  )
}


const buttonStyle = {
  base: {
    width: `100%`,
    background: `unset`,
    border: `2px solid ${colors.primaryBrand}`,
    borderRadius: `2px`,
    padding: `${space[1]}px`,
    fontWeight: `500`,
    cursor: `pointer`,
    transition: `color 300ms, border-color 300ms, background-color 300ms,`
  },
  selected: {
    borderColor: colors.darkGray,
    backgroundColor: colors.darkGray,
    color: colors.lightGray,
  },
}

export default MaterialSelectionCard