import React, { useState, useEffect, useContext } from 'react'
import { css } from 'styled-components'
import { Link, navigate } from 'gatsby'
import Alert from '@reach/alert'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Layout from '../../../components/CampaignLandingPage/CampaignLayout'
import WhiteHeader from '../../../components/InstantQuote/WhiteHeader'
import DropZone from '../../../components/InstantQuote/DropZone'

import { button } from '../../../utils/styles'
import {
  fontSizes,
  mediaQueries,
  space,
  colors,
  boxHeights,
  palette,
} from '../../../utils/tokens'

import { generateUniqueFileName } from '../../../utils/generateUniqueFileName'

import {
  calculatePrice,
  calculateMinimumOrderValueSurcharge,
} from '../../../logic/pricing'

import { ModelCard } from '../../../components/InstantQuote/ModelCard'
import CanvasModal from '../../../components/InstantQuote/webgl/CanvasModal'
import FormLabel from '../../../components/InstantQuote/FormLabel'
import MaterialSelectionList from '../../../components/InstantQuote/MaterialSelectionList'
import SubmitModalScreen from '../../../components/InstantQuote/SubmitModalScreen'
import { UserContext } from '../../../context/UserManager'
import { BottomNav } from '../components/BottomNav'
import { Container } from '../components/Container'
import BottomNavRetail from '../components/BottomNavRetail'
import SubmitModalScreenAnon from '../../../components/InstantQuote/SubmitModalScreenAnon'
import useFirebase from '../../../hooks/useFirebase'


const InstantQuote = ({
  anonymousUser,
}) => {
  const [models, setModels] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [showFileError, setShowFileError] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [selectedModelIndex, setSelectedModelIndex] = useState(0)
  const [selectedMaterialId, setSelectedMaterialId] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [valueSurcharge, setValueSurcharge] = useState(0)
  const [partnerPrice, setPartnerPrice] = useState(0)
  const [isFilesUploaded, setIsFilesUploaded] = useState(false)
  const [isDataUploaded, setIsDataUploaded] = useState(false)
  const [additionalInformation, setAdditionalInformation] = useState("")

  // for anon users
  const [showAnonSubmitModal, setShowAnonSubmitModal] = useState(false)
  const [isFilesUploading, setIsFilesUploading] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')


  // analytics
  const [isUsed, setIsUsed] = useState(false)

  const firebase = useFirebase()
  const { user } = useContext(UserContext)
 
  // update total price every time the model changes
  useEffect(() => {
    if (models.length > 0) {
      let runningTotal = 0
      models.forEach(model => {
        console.log({ volume: model.volume, price: (model.volume / 1000) * 0.04 })
        runningTotal += calculatePrice({
          volume: model.volume,
          quantity: model.quantity,
          unit: model.unit,
        })
      })

      const fixedRunningTotal = parseFloat(runningTotal.toFixed(2))

      setValueSurcharge(calculateMinimumOrderValueSurcharge(fixedRunningTotal))
      setPartnerPrice(fixedRunningTotal * 0.6)
      setTotalPrice(fixedRunningTotal)
    }

    // reset price when models is empty
    if (models.length === 0) {
      setTotalPrice(0)
      setPartnerPrice(0)
      setValueSurcharge(0)
    }
  }, [models])

  // let google analytics know that someone used the instant quote
  // for the first time
  useEffect(() => {
    if(models.length > 0) {
      if(isUsed) {
      } else {
        // For google analytics
        trackCustomEvent({
          category: `InstantQuote`,
          action: `FileAdded`,
          label: `Instant Quote Beta`,
        })
        setIsUsed(true)
      }
    }
  }, [models])

  const handleModelInformation = model => {
    // weird effect here
    // i have to set models twice to grab all the models uploaded
    // HACK
    setModels(prev => [
      ...prev,
      {
        bufferGeometry: model.geometry,
        dimensions: {
          x: model.dimensions.x,
          y: model.dimensions.y,
          z: model.dimensions.z,
        },
        fileBlob: model.fileBlob,
        name: model.fileName,
        volume: model.volume,
        price: calculatePrice({
          volume: model.volume,
          quantity: 1,
          unit: 'mm',
        }),
        quantity: 1,
        unit: 'mm',
      },
    ])
    
    setModels(prev => [
      ...prev,
      {
        bufferGeometry: model.geometry,
        dimensions: {
          x: model.dimensions.x,
          y: model.dimensions.y,
          z: model.dimensions.z,
        },
        fileBlob: model.fileBlob,
        name: model.fileName,
        volume: model.volume,
        price: calculatePrice({
          volume: model.volume,
          quantity: 1,
          unit: 'mm',
        }),
        quantity: 1,
        unit: 'mm',
      },
    ])
  }

  const handleUnitChange = (value, id) => {
    setModels(
      models.map((item, itemId) =>
        itemId === id ? {
          ...item,
          unit: value,
          price: calculatePrice({
            unit: value,
            quantity: item.quantity,
            volume: item.volume,
        }) } : item
      )
    )
  }

  const handleQuantityChange = (value, id) => {
    setModels(
      models.map((item, itemId) =>
        itemId === id ? {
          ...item,
          quantity: value,
          price: calculatePrice({
            unit: item.unit,
            volume: item.volume,
            quantity: value,
          })
        } : item
      )
    )
  }

  const handleRemoveModel = id => {
    const filteredModels = models.filter((_, index) => id !== index)
    setModels(filteredModels)
  }

  const handleRemoveAllModels = () => {
    setModels([])
  }

  const handleChangeAllUnits = value => {
    setModels(
      models.map(model => {
        return {
          ...model,
          unit: value,
          price: calculatePrice({
            volume: model.volume,
            quantity: model.quantity,
            unit: value,
          }),
        }
      })
    )
  }

  const handleAdditionalInformationChange = (e) => {
    setAdditionalInformation(e.target.value)
  }

  const handleViewModel = id => {
    setSelectedModelIndex(id)
    setModalOpen(true)
  }

  const handleDismissCanvas = id => {
    setModalOpen(false)
  }

  const handleMaterialSelect = id => {
    setSelectedMaterialId(id)
  }

  const handleFileUpload = ({
    rootPath = 'models'
  }) => {
    const storageRef = firebase.storage().ref()

    let uploadPromises = []

    for(let i = 0; i < models.length; i++) {
      const model = models[i]
      const uniqueFileName = generateUniqueFileName(model.name)
      const modelFileRef = storageRef.child(`${rootPath}/${uniqueFileName}`)

      const uploadPromise = new Promise((resolve, reject) => {
        const uploadTask = modelFileRef.put(model.fileBlob)
        uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(downloadURL => {
            resolve(downloadURL)
          })
      })
      uploadPromises[i] = uploadPromise
    }

    return Promise.all(uploadPromises)
  }

  const getUserProfileDocId= async (user) => {
    try {
      const db = firebase.firestore()
      return db.collection('profiles')
        .where('uid', '==', user.uid)
        .limit(1)
        .get()
    } catch (error) {
      throw new Error(error)
    }
  }

  const uploadDocument = async (downloadUrls) => {
    if(anonymousUser) {
      return
    }
    // add downloadUrl to each model
    // it also removes BufferGeometry from
    // our data since firebase cannot process that.
    const newModels = models.map((model, id) => {
      return {
        dimensions: model.dimensions,
        name: model.name,
        price: model.price,
        quantity: model.quantity,
        volume: model.volume,
        unit: model.unit,
        downloadURL: downloadUrls[id],
      }
    })

    const db = firebase.firestore()

    try {
      // grab user profile
      const snapshot = await getUserProfileDocId(user)
      const profileDocId = snapshot.docs[0].id
      const data = {
        models: newModels,
        totalPrice: parseFloat(totalPrice.toFixed(2)),
        partnerPrice: parseFloat(partnerPrice.toFixed(2)),
        ownerReference: `profiles/${profileDocId}`,
        ownerName: user.displayName,
        ownerEmail: user.email,
      }

      // add project to database
      db.collection('projects').add(data)
    } catch (error) {
      setIsDataUploaded(false)
      throw new Error(error)
    }
  }
  

  const updateAllModelURl = (urls) => {
    setModels(
      models.map((model, modelId) => {
        return {
          ...model,
          downloadURL: urls[modelId],
        }
      })
    )
  }

  const handleSubmit = async () => {
    setShowSubmitModal(true)
    try {
      const downloadUrls = await handleFileUpload({ rootPath: `models`})
      updateAllModelURl(downloadUrls)
      await uploadDocument(downloadUrls)
      setIsFilesUploaded(true)
      setIsDataUploaded(true)
    } catch (error) {
      setIsFilesUploaded(false)
      throw new Error(error)
    }
  }

  const handleAnonSubmit = async () => {
    // have user add email and name
    // run doc upload after submit
    try {
      setIsFilesUploading(true)
      const downloadUrls = await handleFileUpload({ rootPath: `anonModels`})
      setIsFilesUploading(false)
      // this is actually not doing anything
      // we need to pass download urls to uploadAnonDocument
      // so we can get the download urls right away
      // and we don't need to wait for component to mount again
      updateAllModelURl(downloadUrls)

      await uploadAnonDocument(downloadUrls)

      // For google analytics
      trackCustomEvent({
        category: `InstantQuoteAnon`,
        action: `Submit`,
        label: `Instant Quote Beta`,
      })

      navigate(`/success/3d-printing/`)
    } catch (error) {
      throw new Error(error)
    }
  }

  const uploadAnonDocument = async (downloadUrls) => {
    const db = firebase.firestore()


    // add downloadUrl to each model
    // it also removes BufferGeometry from
    // our data since firebase cannot process that.
    const newModels = models.map((model, id) => {
      return {
        dimensions: model.dimensions,
        name: model.name,
        price: model.price,
        quantity: model.quantity,
        volume: model.volume,
        unit: model.unit,
        downloadURL: downloadUrls[id],
      }
    })

    const data = {
      models: newModels,
      totalPrice: parseFloat(totalPrice.toFixed(2)),
      valueSurcharge: valueSurcharge,
      email: email,
      name: name,
      additionalInformation: additionalInformation,
    }

    db.collection(`anonProjects`).add(data)
  }

  return (
    <Layout hideFooter>
      <WhiteHeader />
      <main>
        <Container
          css={css({
            display: `grid`,
            gridGap: `${space[4]}px`,
            [mediaQueries.xl]: {
              display: `grid`,
              gridTemplateColumns: `13fr 11fr`,
              gridGap: 0,
            },
          })}
        >
          <div
            css={css({
              [mediaQueries.xl]: {
                height: `calc(100vh - ${boxHeights.plainHeader})`,
              },
            })}
          >
            <div
              css={css({
                padding: `${space[5]}px ${space[3]}px`,
                [mediaQueries.xl]: {
                  padding: `${space[5]}px ${space[5]}px`,
                  height: `calc(100vh - ${boxHeights.plainHeader})`,
                  overflowY: `scroll`,
                },
              })}
            >
              <div
                css={css({
                  marginBottom: `${space[4]}px`,
                })}
              >
                <FormLabel
                  css={css({
                    marginBottom: `${space[3]}px`,
                  })}
                >
                  Upload your 3D File
                </FormLabel>
                <div
                  css={css({
                    display: `grid`,
                    gridGap: `${space[3]}px`,
                    [mediaQueries.xl]: {
                      gridTemplateColumns: `1fr auto auto`,
                      alignItems: `center`,
                      gridGap: `${space[4]}px`,
                    },
                  })}
                >
                  <DropZone
                    items={models}
                    getModelInformation={model => handleModelInformation(model)}
                    getIsFileRejected={isRejected => {
                      setShowFileError(isRejected)
                    }}
                  />
                  <div
                    css={css({
                      justifySelf: `center`,
                    })}
                  >
                    <span
                      css={css({
                        fontWeight: `bold`,
                      })}
                    >
                      or
                    </span>
                  </div>
                  <Link
                    to="/services/3d-modeling/"
                    css={css({
                      ...button.base,
                      ...button.dark,
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      fontWeight: `500`,
                      textTransform: `none`,
                      textDecoration: `none`,
                      padding: `${space[2]}px ${space[4]}px`,
                    })}
                  >
                    Don't have a 3D model?
                  </Link>
                </div>
                {showFileError ? (
                  <Alert
                    css={css({
                      fontSize: fontSizes[1],
                      marginTop: `${space[3]}px`,
                      borderRadius: `4px`,
                      textAlign: `center`,
                      background: 'hsla(10, 50%, 50%, .10)',
                      padding: `${space[3]}px`,
                      color: palette.red[90],
                      backgroundColor: palette.red[20],
                      fontWeight: `500`,
                      position: `relative`,
                    })}
                  >
                    <button
                      css={css({
                        border: `none`,
                        background: `unset`,
                        color: palette.red[80],
                        fontSize: fontSizes[2],
                        position: `absolute`,
                        top: 0,
                        right: `${space[2]}px`,
                        height: `100%`,
                        cursor: `pointer`,
                      })}
                      onClick={() => setShowFileError(false)}
                    >
                      x
                    </button>
                    <div>
                      <span>File size is too large. We suggest using our </span>
                      <Link to="/get-quote/">custom quoting system</Link>
                    </div>
                  </Alert>
                ) : null}
              </div>
              <section
                css={css({
                  display: `flex`,
                  flexDirection: `column`,
                  marginBottom: `${space[4]}px`,
                })}
              >
                {models.length > 0 ? (
                  <div
                    css={css({
                      display: `grid`,
                      justifyContent: `center`,
                      gridTemplateColumns: `1fr 1fr`,
                      gridColumnGap: `${space[3]}px`,
                      padding: `${space[3]}px ${space[2]}px`,
                      paddingLeft: `${space[3]}px`,
                      [mediaQueries.xl]: {
                        marginLeft: `calc(96px + 8px)`,
                      },
                    })}
                  >
                    <div
                      css={css({
                        border: 0,
                        color: colors.primaryBrand,
                        cursor: `pointer`,
                        background: `unset`,
                        fontWeight: `500`,
                        display: `flex`,
                        alignItems: `center`,
                      })}
                    >
                      Change all units
                      <select
                        css={css({
                          marginLeft: `${space[2]}px`,
                          height: `32px`,
                          backgroundColor: `unset`,
                          borderRadius: `2px`,
                          border: `1px solid ${colors.veryLightBase}`,
                        })}
                        onChange={e => handleChangeAllUnits(e.target.value)}
                      >
                        <option>mm</option>
                        <option>in</option>
                      </select>
                    </div>
                    <button
                      css={css({
                        cursor: `pointer`,
                        border: 0,
                        color: colors.danger,
                        background: `unset`,
                        fontWeight: `500`,
                        justifySelf: `flex-end`,
                      })}
                      onClick={handleRemoveAllModels}
                    >
                      Remove all
                    </button>
                  </div>
                ) : null}
                <div>
                  {models &&
                    models.map((model, id) => {
                      return (
                        <div
                          key={id}
                          css={css({
                            marginBottom: `${space[3]}px`,
                          })}
                        >
                          <ModelCard
                            name={model.name}
                            dimensions={model.dimensions}
                            unit={model.unit}
                            geometry={model.bufferGeometry}
                            price={model.price}
                            onRemove={() => handleRemoveModel(id)}
                            onUnitChange={value => handleUnitChange(value, id)}
                            onQuantityChange={value =>
                              handleQuantityChange(value, id)
                            }
                            onViewModel={() => handleViewModel(id)}
                          />
                        </div>
                      )
                    })}
                </div>
              </section>
            </div>
          </div>
          <div
            css={css({
              position: `relative`,
              width: `100%`,
              paddingTop: `${space[4]}px`,
              [mediaQueries.xl]: {
                height: `calc(100vh - ${boxHeights.plainHeader})`,
                width: `100%`,
                display: `flex`,
                flexDirection: `column`,
                padding: `0`,
                backgroundColor: palette.grey[20],
              },
            })}
          >
            {models.length === 0 && (
              <div
                css={css({
                  position: `absolute`,
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  width: `100%`,
                  height: `100%`,
                  backgroundColor: colors.white,
                  opacity: 0.5,
                })}
              />
            )}
            <div
              css={css({
                height: `inherit`,
                flex: 1,
                [mediaQueries.xl]: {
                  overflowY: `scroll`,
                  padding: `${space[5]}px ${space[4]}px`,
                },
              })}
            >
              <MaterialSelectionList
                materials={materials}
                onMaterialSelect={handleMaterialSelect}
                selectedMaterialId={selectedMaterialId}
                areModelsLoaded={models.length > 0}
              />
            </div>
            {anonymousUser ? (
              <BottomNavRetail
                subTotal={totalPrice}
                valueSurcharge={valueSurcharge}
                onSubmit={() => setShowAnonSubmitModal(true)}
              />
            ) : (
              <BottomNav
                price={totalPrice || 0}
                onSubmit={handleSubmit}
                partnerPrice={partnerPrice}
              />
            )}
          </div>
        </Container>
      </main>
      <CanvasModal
        models={models}
        isOpen={isModalOpen}
        onDismiss={handleDismissCanvas}
        startingModelIndex={selectedModelIndex}
      />
      <SubmitModalScreen
        isOpen={showSubmitModal}
        isUploading={!isFilesUploaded}
        isDataSubmitted={isDataUploaded}
        onDismiss={() => {
          setShowSubmitModal(false)
          handleRemoveAllModels()
          setIsFilesUploaded(false)
        }}
      />
      <SubmitModalScreenAnon
        isOpen={showAnonSubmitModal}
        isUploading={isFilesUploading}
        onDismiss={() => setShowAnonSubmitModal(false)}
        onEmailChange={text => setEmail(text)}
        onNameChange={text => setName(text)}
        onAdditionalInformationChange={handleAdditionalInformationChange}
        onSubmit={handleAnonSubmit}
        name={name}
        email={email}
        additionalInformation={additionalInformation}
      />
    </Layout>
  )
}

const materials = [
  {
    name: `SOMOS Evolve Resin`,
    description: `High-detailed models, architecture, art sculptures, and engineering prototypes.`,
  },
  {
    name: `Tough Resin(ABS-Like)`,
    description: `Engineering parts. High-detailed and durable`,
  },
  {
    name: `High-detailed Resin`,
    description: `Small pieces with very intricate design`,
  },
]

export default InstantQuote
