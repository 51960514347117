import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { space, palette, colors } from '../../../utils/tokens'

const FormSubmitButton = ({
  label,
  onClick,
  isLoading,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      css={css({
        ...buttonStyle,
      })}
      {...rest}
    >{isLoading ? <LoadingRing color={colors.white} /> : label}</button>
  )
}

const ringAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Ring = styled.div`
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;

  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid ${props => props.color || colors.primaryBrand};
    border-radius: 50%;
    animation: 1.2s ${ringAnimation} cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color || colors.primaryBrand} transparent
      transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const LoadingRing = ({ color }) => {
  return (
    <Ring
      color={color}
    >
      <div />
      <div />
      <div />
      <div />
    </Ring>
  )
}

const buttonStyle = {
  border: `none`,
  backgroundColor: palette.orange[40],
  borderRadius: `4px`,
  alignSelf: `flex-end`,
  color: colors.white,
  fontWeight: `bold`,
  letterSpacing: `1px`,
  cursor: `pointer`,
  height: `48px`,
  minWidth: `120px`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0 ${space[3]}px`,
}

export default FormSubmitButton