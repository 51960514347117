import React, { useCallback, useState, useEffect } from 'react'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2'
import { useDropzone } from 'react-dropzone'
import { css } from 'styled-components'

import { getModelDimensions } from '../../utils/getModelDimensions'
import { getVolume } from '../../utils/getVolume'
import { space, colors, fontSizes } from '../../utils/tokens'

const DropZone = props => {
  const { getModelInformation, getIsFileRejected } = props

  const [isFileRejected, setFileRejected] = useState(false)

  useEffect(() => {
    getIsFileRejected && getIsFileRejected(isFileRejected)
  }, [isFileRejected])

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach((file, id) => {
        const reader = new FileReader()
        // We limit to 100 Mb
        const maxFileSize = 1000 * 1000 * 150

        if (file.size > maxFileSize) {
          reader.abort()
          setFileRejected(true)
        } else {
          setFileRejected(false)
          reader.readAsArrayBuffer(file)
        }

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const result = reader.result

          const fileExtension = file.name
            .split('.')
            .slice(-1)[0]
            .toLowerCase()

          if (fileExtension === 'stl') {
            const geometry = new STLLoader().parse(result)

            geometry.center()
            const dimensions = getModelDimensions(geometry)
            const volume = getVolume(geometry)

            getModelInformation({
              dimensions,
              volume,
              fileName: file.name,
              fileSize: file.size,
              fileExtension,
              geometry: geometry,
              fileBlob: result,
            })

          } else if (fileExtension === 'obj') {
            const object3D = new OBJLoader2().parse(result)
            const geometry = object3D.children[0].geometry

            geometry.center()
            const dimensions = getModelDimensions(geometry)
            const volume = getVolume(geometry)

            getModelInformation({
              dimensions,
              volume,
              fileName: file.name,
              fileSize: file.size,
              fileExtension,
              geometry: geometry,
              fileBlob: result,
            })
          }
        }
      })
    }
  , [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })

  return (
    <div>
      <div
        css={css({
          padding: `${space[4]}px ${space[5]}px`,
          border: `2px dashed ${colors.darkGray}`,
          borderRadius: `8px`,
          backgroundColor: colors.mute,
        })}
        {...getRootProps({ refKey: 'innerRef' })}
      >
        <input {...getInputProps()} accept=".stl, .obj" />
        <div
          css={css({
            textAlign: `center`,
          })}
        >
          <p
            css={css({
              fontWeight: `bold`,
              fontSize: fontSizes[2],
              color: `${colors.darkGray}`,
              marginBottom: `${space[1]}px`,
            })}
          >
            Drop your file or click here
          </p>
          <p
            css={css({
              fontSize: fontSizes[1],
            })}
          >
            We only accept .STL and .OBJ files under 150Mb
          </p>
        </div>
      </div>
    </div>
  )
}

export default DropZone