import React from 'react'
import styled, { keyframes } from 'styled-components'

const AnimatedEllipsis = ({
  label,
  className,
}) => {
  return <Animation className={className}>{label}</Animation> 
}

const ellipsis = keyframes`
  to {
    width: 2rem;    
  }
`

const Animation = styled.div`
  ::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${ellipsis} steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }
`

export default AnimatedEllipsis