import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton'
import { css } from 'styled-components';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md'
import { IoIosCube } from 'react-icons/io'

import { fontSizes, mediaQueries, space, colors, palette } from '../../utils/tokens';


const InformationSection = ({
  dimensions,
  onUnitChange,
  onQuantityChange,
  price,
  unit = "mm",
  isPriceHidden
}) => {
  return (
    <div
      css={css({
        display: `grid`,
        padding: `${space[2]}px`,
        gridRowGap: `${space[2]}px`,
        [mediaQueries.xl]: {
          gridTemplateColumns: `2fr 1fr 1fr`,
          justifyContent: `space-between`,
          gridGap: `${space[3]}px`,
          padding: 0,
        },
      })}
    >
      <div
        css={css({
          fontWeight: `500`,
          fontSize: fontSizes[1],
        })}
      >
        <div
          css={css({
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            [mediaQueries.xl]: {
              display: `block`,
            },
          })}
        >
          <span
            css={css({
              fontWeight: `500`,
            })}
          >
            {dimensions.x} x {dimensions.y} x {dimensions.z}
          </span>
          <select
            css={css({
              marginLeft: `${space[2]}px`,
              height: `32px`,
              backgroundColor: `unset`,
              borderRadius: `2px`,
              border: `1px solid ${palette.grey[40]}`,
              fontWeight: `bold`,
            })}
            onChange={e => onUnitChange(e.target.value)}
            value={unit}
          >
            <option>mm</option>
            <option>in</option>
          </select>
        </div>
      </div>
      <div>
        <div
          css={css({
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            [mediaQueries.xl]: {
              display: `block`,
            },
          })}
        >
          <QuantityInput onQuantityChange={onQuantityChange} />
        </div>
      </div>
      {isPriceHidden ? (
        <Skeleton height={32} />
      ) : (
        <div
          css={css({
            display: `flex`,
            justifyContent: `center`,
            width: `100%`,
            [mediaQueries.xl]: {
              justifySelf: `flex-end`,
              paddingRight: `${space[2]}px`,
              alignItems: `center`,
              justifyContent: `flex-end`,
            },
          })}
        >
          <span
            css={css({
              fontWeight: `bold`,
              fontSize: fontSizes[1],
              color: palette.grey[70],
            })}
          >
            {price}
          </span>
        </div>
      )}
    </div>
  )
}

export const ModelCard = ({
  name,
  unit = 'mm',
  onViewModel,
  onQuantityChange,
  isPriceHidden,
  onUnitChange,
  onRemove,
  price = 0,
  dimensions = {
    x: 0,
    y: 0,
    z: 0,
  },
}) => {

  // Upload file when card is first mounted

  const handleRemove = () => {
    onRemove()
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  const formattedPrice = formatter.format(price)

  return (
    <div
      css={css({
        borderRadius: `4px`,
        border: `1px solid ${palette.grey[40]}`,
        borderBottom: `none`,
        backgroundColor: colors.white,
        position: `relative`,
        overflow: `hidden`,
        boxShadow: `0 4px 0 ${colors.primaryBrand}`,
      })}
    >
      <div
        css={css({
          display: `grid`,
          gridTemplateColumns: `auto 1fr`,
          [mediaQueries.md]: {
            gridColumnGap: `${space[3]}px`,
            alignItems: `center`,
            padding: `${space[2]}px`,
          },
        })}
      >
        <div
          css={css({
            gridRow: `span 2`,
          })}
        >
            <button
              css={css({
                width: `96px`,
                height: `96px`,
                background: `none`,
                cursor: `pointer`,
                borderRadius: `2px`,
                backgroundColor: colors.lightGray,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
                alignItems: `center`,
                color: palette.grey[70],
                boxShadow: `0 5px 15px hsla(0,0%,.2)`,
              })}
              onClick={onViewModel}
            >
              <IoIosCube
                css={css({
                  fontSize: fontSizes[3],
                })}
              />
              <span>View</span>
            </button>
        </div>
        <div
          css={css({
            gridRow: `span 2`,
            gridColumn: 2,
            display: `flex`,
            alignItems: `flex-start`,
            justifyContent: `space-between`,
            alignSelf: `center`,
            [mediaQueries.lg]: {
              gridRow: 1,
              gridColumn: 2,
            },
          })}
        >
          <div>
            <h5
              css={css({
                fontSize: fontSizes[2],
                fontWeight: `bold`,
                marginLeft: `${space[2]}px`,
                [mediaQueries.xl]: {
                  marginLeft: 0,
                  marginRight: `${space[1]}px`,
                },
              })}
            >
              {name}
            </h5>
          </div>
          <button
            css={css({
              background: `none`,
              color: colors.danger,
              fontWeight: `500`,
              border: `none`,
              fontSize: fontSizes[1],
              cursor: `pointer`,
            })}
            onClick={handleRemove}
          >
            Remove
          </button>
        </div>
        <div
          css={css({
            gridRow: 3,
            gridColumn: `span 2`,
            [mediaQueries.xl]: {
              gridRow: 2,
              gridColumn: 2,
            },
          })}
        >
          <InformationSection
            isPriceHidden={isPriceHidden}
            dimensions={dimensions}
            onQuantityChange={onQuantityChange}
            onUnitChange={onUnitChange}
            price={formattedPrice}
            unit={unit}
          />
        </div>
      </div>
    </div>
  )
}


const QuantityInput = ({
  onQuantityChange,
}) => {
  const [value, setValue] = useState(1)
  
  useEffect(() => {
    onQuantityChange(value)
  }, [value])

  const handleQuantityChange = (e) => {
    if(!e.target.value) {
      setValue("")
    }

    const int = parseInt(e.target.value)
    if (int < 0 || int === 0) {
      setValue(1)
    } else {
      setValue(int)
    }
  }

  const handleBlur = (e) => {
    const int = parseInt(e.target.value)

    if(!e.target.value) {
      setValue(1)
    } else {
      setValue(int)
    }
  }

  const addQuantity = () => {
    setValue(value + 1)
  }

  const subtractQuantity = () => {
    if(value > 1) {
      setValue(value - 1)
    }
  }

  const isMinimumQuantity = value === 1

  return (
    <div
      css={css({
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      })}
    >
      <button
        onClick={addQuantity}
        css={css({
          background: `none`,
          border: `none`,
          color: palette.grey[70],
          cursor: `pointer`,
          fontSize: fontSizes[3],
        })}
      >
        <MdAddCircleOutline />
      </button>
      <input
        onBlur={handleBlur}
        css={css`
          border-radius: 2px;
          border: 1px solid ${palette.grey[40]};
          width: 64px;
          height: 32px;
          font-weight: bold;
          text-align: center;
          ::-webkit-outer-spin-button,
          ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
        type="number"
        value={value}
        min={1}
        max={500}
        onChange={handleQuantityChange}
      />
      <button
        disabled={isMinimumQuantity}
        onClick={subtractQuantity}
        css={css({
          background: `none`,
          border: `none`,
          color: palette.grey[70],
          cursor: `pointer`,
          fontSize: fontSizes[3],
          opacity: isMinimumQuantity ? 0.3 : 1,
        })}
      >
        <MdRemoveCircleOutline />
      </button>
    </div>
  )
}