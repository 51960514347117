import React from 'react'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { css } from 'styled-components'
import { IoMdCloseCircleOutline } from 'react-icons/io'

import { space, fontSizes, colors } from '../utils/tokens'

const Modal = ({
  contentCss,
  overlayCss,
  isOpen,
  onDismiss,
  ariaLabel,
  children,
}) => {
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onDismiss}
      css={overlayCss}
    >
      <DialogContent
        css={css({
          maxWidth: `600px`,
          width: `calc(100% - ${space[5]}px)`,
          position: `relative`,
        })}
        css={contentCss}
        aria-label={ariaLabel}
      >
        <button
          aria-label="close"
          onClick={onDismiss}
          css={css({
            border: 0,
            alignSelf: `flex-end`,
            background: `unset`,
            padding: 0,
            display: `flex`,
            alignItems: `center`,
            cursor: `pointer`,
            position: `absolute`,
            right: `${space[3]}px`,
            top: `${space[3]}px`,
            fontSize: fontSizes[3],
            color: colors.danger,
          })}
        >
          <IoMdCloseCircleOutline />
        </button>
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export default Modal