import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'

import { space, breakpoints, colors, mediaQueries } from '../../utils/tokens'
import PrintAWorldLogo from '../../assets/logo/printaworld-logo-black.svg'

import { UserContext } from '../../context/UserManager'
import useFirebase from '../../hooks/useFirebase'

const WhiteHeader = props => {

  const { user, removeCurrentUser } = useContext(UserContext)
  const firebase = useFirebase()

  const handleLogout = () => {
    firebase.auth().signOut()
    removeCurrentUser()
  }
  
  return (
    <header
      css={css({
        padding: `0 ${space[3]}px`,
        display: `flex`,
        alignItems: `center`,
        height: `${space[5]}px`,
        maxWidth: breakpoints.xxxl,
        width: `100%`,
        margin: `0 auto`,
        zIndex: 1,
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.tertiary}`,
        justifyContent: `space-between`,
        [mediaQueries.lg]: {
          padding: `0 ${space[4]}px`,
        },
      })}
    >
      <Link to="/">
        <PrintAWorldLogo
          css={css({
            height: `${space[4]}px`,
            fill: colors.black,
          })}
        />
      </Link>
      {user ? (
        <div
          css={css({
            fontWeight: `500`,
          })}
        >
          <button
            css={css({
              background: `none`,
              border: 0,
              cursor: `pointer`,
            })}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      ) : null }
    </header>
  )
}

export default WhiteHeader
