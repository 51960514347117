import React from 'react'
import { css } from 'styled-components'
import { FaCheck } from 'react-icons/fa'

import LoadingRing from '../LoadingRing'
import Modal from '../Modal'

import { button } from '../../utils/styles'
import { space, mediaQueries, fontSizes, colors } from '../../utils/tokens'

const SubmitModalScreen = ({
  isOpen,
  onDismiss,
  isUploading,
  isDataSubmitted,
}) => {

  const handleDismiss = () => {
    // dont dismiss if we're still uploading
    if(isUploading || !isDataSubmitted) {
      return
    }
    onDismiss()
  }
  
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      ariaLabel="Submit information"
    >
        <div
          css={css({
            marginBottom: `${space[4]}px`,
          })}
        >
          {isUploading ? (
            <div>
              <h5
                css={css({
                  ...titleStyle,
                  marginBottom: `${space[4]}px`,
                })}
              >
                Uploading your files right now
              </h5>
            </div>
          ) : (
            <div
              css={css({
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
              })}
            >
              <h5
                css={css({
                  ...titleStyle,
                })}
              >
                Uploading finished
              </h5>
            </div>
          )}
        </div>
        <div
          css={css({
            display: `flex`,
            justifyContent: `center`,
            marginBottom: `${space[4]}px`,
          })}
        >
          {isUploading ? (
            <LoadingRing />
          ) : (
            <FaCheck
              css={css({
                height: `80px`,
                width: `80px`,
                color: colors.success,
              })}
            />
          )}
        </div>
        <button
          css={css({
            ...button.base,
            ...button.primary,
            borderRadius: `6px`,
            paddingTop: `${space[2]}px`,
            paddingBottom: `${space[2]}px`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            width: `100%`,
            [mediaQueries.lg]: {
              width: `100%`,
            },
            opacity: isUploading ? 0.5 : 1,
            cursor: isUploading ? `not-allowed` : `pointer`,
          })}
          disabled={isUploading}
          onClick={handleDismiss}
        >
          Okay
        </button>
    </Modal>
  )
}

const titleStyle = {
  textAlign: `center`,
  fontSize: fontSizes[3],
}

export default SubmitModalScreen