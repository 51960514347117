import React from 'react'
import { css } from 'styled-components'

import { fontSizes } from '../../utils/tokens'

const FormLabel = ({ children, ...rest }) => {
  return (
    <h3
      css={css({
        fontWeight: `bold`,
        fontSize: fontSizes[3],
      })}
      {...rest}
    >
      {children}
    </h3>
  )
}

export default FormLabel