import React from 'react';
import { css } from 'styled-components';
import { IoIosFastforward } from 'react-icons/io'
import { MdAccessTime } from 'react-icons/md';
import { button } from '../../../utils/styles';
import { fontSizes, mediaQueries, breakpoints, space, colors, boxHeights, boxShadows, palette } from '../../../utils/tokens';

export const BottomNav = ({ price, partnerPrice, onSubmit, }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const formattedPrice = formatter.format(price);
  const formattedPartnerPrice = formatter.format(partnerPrice);
  const formattedStandardPrice = formatter.format(price * .4);
  return (
    <div
      css={css({
        width: `100%`,
        [mediaQueries.xl]: {
          minHeight: boxHeights.instantQuoteBottom,
        },
      })}
    >
      <div
        css={css({
          width: `100%`,
          height: `100%`,
          backgroundColor: colors.white,
          padding: `${space[3]}px ${space[3]}px`,
          [mediaQueries.md]: {
            padding: `${space[4]}px ${space[5]}px`,
          },
          [mediaQueries.xl]: {
            padding: `${space[4]}px ${space[4]}px ${space[5]}px`,
            boxShadow: boxShadows.bottomNav,
            border: `1px solid ${palette.grey[40]}`,
            borderBottom: `none`,
          },
        })}
      >
        <div
          css={css({
            display: `flex`,
            alignItems: `flex-start`,
            flexDirection: `column-reverse`,
            justifyContent: `space-between`,
            [mediaQueries.xl]: {
              maxWidth: breakpoints.sm,
            },
          })}
        >
          <button
            css={css({
              ...button.base,
              ...button.primary,
              borderRadius: `6px`,
              border: `none`,
              paddingTop: `${space[2]}px`,
              paddingBottom: `${space[2]}px`,
              display: `flex`,
              justifyContent: `center`,
              alignSelf: `flex-end`,
              alignItems: `center`,
              width: `100%`,
              [mediaQueries.lg]: {
                width: `auto`,
              },
            })}
            onClick={onSubmit}
          >
            SEND FOR APPROVAL
          </button>
          <div
            css={css({
              width: `100%`,
              marginBottom: `${space[2]}px`,
              [mediaQueries.md]: {
                marginBottom: `${space[3]}px`,
              },
              [mediaQueries.xl]: {
                marginBottom: 0,
              },
            })}
          >
            <div
              css={css({
                marginBottom: `${space[2]}px`,
              })}
            >
              <span
                css={css({
                  color: colors.base,
                  fontWeight: `bold`,
                  fontSize: fontSizes[2],
                  marginRight: `${space[2]}px`,
                  [mediaQueries.md]: {
                    fontSize: fontSizes[2],
                    marginRight: `${space[3]}px`,
                  },
                })}
              >
                Retail price:
              </span>
              <span
                css={css({
                  fontWeight: `500`,
                  color: `${colors.base}`,
                  [mediaQueries.md]: {
                    fontSize: fontSizes[2],
                  },
                })}
              >
                {formattedPrice ? formattedPrice : `--`}
              </span>
            </div>
            <div
              css={css({
                display: `flex`,
                flexDirection: `column`,
              })}
            >
              <span
                css={css({
                  color: colors.primaryBrand,
                  marginBottom: `${space[2]}px`,
                  fontWeight: `bold`,
                  fontSize: fontSizes[3],
                  marginRight: `${space[2]}px`,
                  [mediaQueries.md]: {
                    fontSize: fontSizes[4],
                    marginRight: `${space[3]}px`,
                  },
                  [mediaQueries.lg]: {
                    fontSize: fontSizes[3],
                  },
                })}
              >
                Partner Price:
              </span>
              <div
                css={css({
                  display: `flex`,
                  alignItems: `center`,
                  width: `100%`,
                  marginBottom: `${space[3]}px`,
                })}
              >
                <IoIosFastforward
                  css={css({
                    color: palette.orange[50],
                    fontSize: fontSizes[3],
                    marginRight: `${space[2]}px`,
                  })}
                />
                <div
                  css={css({
                    flex: 1,
                    display: `flex`,
                    flexDirection: `column`,
                  })}
                >
                  <span
                    css={css({
                      fontSize: fontSizes[3],
                      fontWeight: `500`,
                      marginRight: `${space[2]}px`,
                    })}
                  >
                    Blazing Fast
                  </span>
                  <span
                    css={css({
                      fontSize: fontSizes[1],
                    })}
                  >
                    (~1 week production time)
                  </span>
                </div>
                <span
                  css={css({
                    fontWeight: `bold`,
                    color: palette.orange[50],
                    fontSize: fontSizes[3],
                    marginRight: `${space[2]}px`,
                    [mediaQueries.md]: {
                      fontSize: fontSizes[4],
                    },
                    [mediaQueries.lg]: {
                      fontSize: fontSizes[3],
                    },
                  })}
                >
                  {formattedPartnerPrice}
                </span>
                <span
                  css={css({
                    fontSize: fontSizes[0],
                    backgroundColor: colors.success,
                    padding: `2px 4px`,
                    color: colors.white,
                  })}
                >
                  40% OFF
                </span>
              </div>
              <div
                css={css({
                  display: `flex`,
                  alignItems: `center`,
                  width: `100%`,
                  marginBottom: `${space[3]}px`,
                })}
              >
                <MdAccessTime
                  css={css({
                    color: palette.grey[70],
                    fontSize: fontSizes[3],
                    marginRight: `${space[2]}px`,
                  })}
                />
                <div
                  css={css({
                    flex: 1,
                    display: `flex`,
                    flexDirection: `column`,
                  })}
                >
                  <span
                    css={css({
                      fontSize: fontSizes[3],
                      fontWeight: `500`,
                      flex: 1,
                    })}
                  >
                    Standard
                  </span>
                  <span
                    css={css({
                      fontSize: fontSizes[1],
                    })}
                  >
                    (2-3 weeks production time)
                  </span>
                </div>
                <span
                  css={css({
                    fontWeight: `bold`,
                    color: palette.orange[50],
                    fontSize: fontSizes[3],
                    marginRight: `${space[2]}px`,
                    [mediaQueries.md]: {
                      fontSize: fontSizes[4],
                    },
                    [mediaQueries.lg]: {
                      fontSize: fontSizes[3],
                    },
                  })}
                >
                  {formattedStandardPrice}
                </span>
                <span
                  css={css({
                    fontSize: fontSizes[0],
                    backgroundColor: colors.success,
                    padding: `2px 4px`,
                    color: colors.white,
                  })}
                >
                  60% OFF
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
